import { navigate } from 'gatsby';
import React from 'react';
import tw from 'twin.macro';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';

import { Text, TextType } from '../core-ui/Text';
import { Wrapper as BaseWrapper } from '../core-ui/Wrapper';
const Wrapper = tw(BaseWrapper)`pt-40 pb-24 md:pb-80`;

import { Button } from '../components/Button';
import { Layout } from '../components/Layout';
import SEO from '../components/SEO';
import { SliceWrapper } from '../core-ui/SliceWrapper';
import { linkResolver } from '../core-ui/utils/linkResolver';
import { componentResolver } from '../core-ui/utils/componentResolver';

const NotFoundPage = () => (
  <Layout title="Not Found">
    <SEO title="404: Not found" />
    <SliceWrapper last>
      <Wrapper>
        <div css={tw`pb-16`}>
          <Text type={TextType.h1}>Page Not Found</Text>
        </div>
        <Text css={tw`mb-16`}>
          In the meantime you can check some of our projects out!
        </Text>
        <Button label="View Projects" onClick={() => navigate('/projects')} />
      </Wrapper>
    </SliceWrapper>
  </Layout>
);

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryName: 'scandidesign',
    linkResolver,
    componentResolver,
  },
]);
